import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import config from "../utils/siteConfig";
import Layout from "../components/Layout/Layout";
import { ContentModules } from "../components/ContentModules";
import SEO from "../components/SEO";

const LandingPageTemplate = ({ data, location, pageContext }) => {
  const { title, modules, darkMode, openGraphData } =
    data.contentfulLandingpage;
  const { slug, seo, pagePath } = pageContext;
  const postNode = data.contentfulLandingpage;
  const isRoot = slug === "";
  const pageTitle = isRoot
    ? `${config.siteTitle}`
    : `${title} - ${config.siteTitle}`;

  return (
    <Layout location={location} inverse={darkMode} slug={slug}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SEO
        {...seo}
        openGraphData={openGraphData}
        slug={slug}
        pagePath={pagePath}
        postNode={postNode}
      />
      <ContentModules modules={modules} />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contentfulLandingpage(id: { eq: $id }) {
      title
      darkMode
      metaDescription {
        internal {
          content
        }
      }
      ...ContentModulesFragment
    }
  }
`;

export default LandingPageTemplate;
